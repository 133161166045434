import React, { FC } from "react"
import { Helmet } from 'react-helmet'
import vromoLogo from "@fleeteng/shared-library/media/vromo-logo.png"
import vromoFavicon from "@fleeteng/shared-library/media/cropped-vromo-favicon.png"

import styles from "./style.module.css"
import { layoutText } from "."

require("../../sentry.client.config.js")

export const Layout1: FC = ({ children }) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {layoutText.title}
                </title>
                <link
                    rel="shortcut icon"
                    href={vromoFavicon}
                    type="image/x-icon"
                />
            </Helmet>
            <div style={{ textAlign: 'center' }}>
                <img src={vromoLogo}
                    alt="vromo logo"
                    className={styles.logoImage}
                />
                <h1>
                    <span>Shopify</span> Integration service
            </h1>
                <div>
                    {children}
                </div>
            </div>
        </React.Fragment>

    )
};


