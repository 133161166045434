import { NextPage } from "next";
import React from "react";
import { Layout1 } from "../layout/layout1";




export const IndexPage: NextPage = () => {
    //throw new Error("Sentry error")
    return (
        <Layout1>

        </Layout1>
    )
};


export default IndexPage
